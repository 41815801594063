var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-img',{attrs:{"src":require("@/assets/FootImage.jpg"),"height":_vm.$vuetify.breakpoint.name == 'xs' || _vm.$vuetify.breakpoint.name == 'sm'
        ? '700px'
        : '670px'}},[_c('div',{staticClass:"white--text Synctext",attrs:{"align":"center"}},[_c('i',[_vm._v(" StepSync ")])]),_c('div',{staticClass:"white--text mx-16",class:_vm.$vuetify.breakpoint.name == 'xs' || _vm.$vuetify.breakpoint.name == 'sm'
          ? ' font-weight-bold mt-14 fontsize20px'
          : 'TrackText'},[_vm._v(" Track your Steps and Compete ")]),_c('div',{staticClass:"white--text MinText mt-8 mx-16"},[_vm._v(" You and your friends are on different phone devices or have different smartwatches?"),_c('br'),_vm._v(" Don't Worry, we got you! ")]),_c('v-row',{staticClass:"ml-8 mt-6"},[_c('v-col',{attrs:{"cols":"12","md":"3","xs":"5","sm":"5"}},[_c('v-img',{attrs:{"src":require("@/assets/PlayStoreImg.png"),"width":_vm.$vuetify.breakpoint.name == 'xs' ||
            _vm.$vuetify.breakpoint.name == 'sm'
              ? '200px'
              : '300px'}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","xs":"5","sm":"5"}},[_c('v-img',{staticClass:"StoreBorder mt-2",attrs:{"src":require("@/assets/AppleApp.png"),"width":_vm.$vuetify.breakpoint.name == 'xs' ||
            _vm.$vuetify.breakpoint.name == 'sm'
              ? '200px'
              : '300px',"height":"100px"}})],1)],1)],1),_c('v-card',{staticClass:"elevation-0",class:_vm.$vuetify.breakpoint.name == 'xs' || _vm.$vuetify.breakpoint.name == 'sm'
        ? 'ma-0'
        : 'ma-16'},[_c('div',{staticClass:"fontsize20px font-weight-bold",attrs:{"align":"center"}},[_c('i',[_vm._v("Why Choose StepSync")])]),_c('v-row',{staticClass:"mt-6",class:_vm.$vuetify.breakpoint.name == 'xs' || _vm.$vuetify.breakpoint.name == 'sm'
          ? 'ma-0'
          : 'ma-16'},_vm._l((_vm.StepSync),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"4","xs":"12","sm":"12"}},[_c('v-img',{staticClass:"mt-n5",attrs:{"src":item.image,"width":"400px"}}),_c('div',{staticClass:"HeadingText mt-4",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(item.heading)+" ")]),_c('div',{staticClass:"mt-12 Textfont"},[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),1)],1),_c('v-toolbar',{attrs:{"color":"black","min-height":"150"}},[_c('v-row',{staticClass:"mt-16",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"4","xs":"12","sm":"12","align":"center"}},[_c('div',{staticClass:"white--text"},[_vm._v(" Copyright © 2023 StepSync - All Rights Reserved. ")])]),_c('v-col',{attrs:{"cols":"12","md":"4","xs":"12","sm":"12","align":"center"}},[_c('a',{staticClass:"white--text",on:{"click":function($event){return _vm.$router.push('/PrivacyPolicy')}}},[_vm._v("Privacy Policy")])]),_c('v-col',{attrs:{"cols":"12","md":"4","xs":"12","sm":"12","align":"center"}},[_c('div',{staticClass:"white--text"},[_vm._v("Powered by Mobil80 Solutions")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }