<template>
  <div>
    <v-img
      src="@/assets/FootImage.jpg"
      class=""
      :height="
        $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          ? '700px'
          : '670px'
      "
    >
      <div class="white--text Synctext" align="center">
        <i> StepSync </i>
      </div>
      <div
        class="white--text mx-16"
        :class="
          $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
            ? ' font-weight-bold mt-14 fontsize20px'
            : 'TrackText'
        "
      >
        Track your Steps and Compete
      </div>
      <div class="white--text MinText mt-8 mx-16">
        You and your friends are on different phone devices or have different
        smartwatches?<br />
        Don't Worry, we got you!
      </div>

      <v-row class="ml-8 mt-6">
        <v-col cols="12" md="3" xs="5" sm="5">
          <v-img
            src="@/assets/PlayStoreImg.png"
            :width="
              $vuetify.breakpoint.name == 'xs' ||
              $vuetify.breakpoint.name == 'sm'
                ? '200px'
                : '300px'
            "
          ></v-img
        ></v-col>
        <v-col cols="12" md="3" xs="5" sm="5">
          <v-img
            src="@/assets/AppleApp.png"
            class="StoreBorder mt-2"
            :width="
              $vuetify.breakpoint.name == 'xs' ||
              $vuetify.breakpoint.name == 'sm'
                ? '200px'
                : '300px'
            "
            height="100px"
          ></v-img
        ></v-col>
      </v-row>
    </v-img>
    <v-card
      class="elevation-0"
      :class="
        $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          ? 'ma-0'
          : 'ma-16'
      "
    >
      <div align="center" class="fontsize20px font-weight-bold">
        <i>Why Choose StepSync</i>
      </div>
      <v-row
        class="mt-6"
        :class="
          $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
            ? 'ma-0'
            : 'ma-16'
        "
      >
        <v-col
          v-for="(item, index) in StepSync"
          :key="index"
          cols="12"
          md="4"
          xs="12"
          sm="12"
        >
          <v-img :src="item.image" width="400px" class="mt-n5"></v-img>
          <div class="HeadingText mt-4" align="center">
            {{ item.heading }}
          </div>
          <div class="mt-12 Textfont">
            {{ item.text }}
          </div></v-col
        >
      </v-row>
    </v-card>
    <v-toolbar color="black" min-height="150">
      <v-row justify="center" class="mt-16">
        <v-col cols="12" md="4" xs="12" sm="12" align="center" class="mt-2">
          <div class="white--text">
            Copyright © 2023 StepSync - All Rights Reserved.
          </div>
        </v-col>
        <v-col cols="12" md="4" xs="12" sm="12" align="center"
          ><a class="white--text" @click="$router.push('/PrivacyPolicy')"
            >Privacy Policy</a
          ></v-col
        >
        <v-col cols="12" md="4" xs="12" sm="12" align="center"
          ><div class="white--text">Powered by Mobil80 Solutions</div></v-col
        >
      </v-row>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  data: () => ({
    StepSync: [
      {
        heading: "Cross-Platform Convenience:",
        image: require("@/assets/StepSync1.webp"),
        text: "StepSync offers a seamless cross-platform experience, allowing users to track their steps, set goals, and engage in challenges with friends on both iOS and Android devices. The app's compatibility with a wide variety of smartwatches ensures that users can effortlessly sync data from their preferred devices, promoting a flexible and inclusive fitness tracking experience.",
      },
      {
        heading: "Wearable Integration for Comprehensive Tracking:",
        image: require("@/assets/StepSync2.webp"),
        text: "With StepSync, users can connect their favorite smartwatches and wearables, allowing for a comprehensive overview of their health and fitness. Whether using a fitness tracker or a smartwatch, users can easily sync data and receive real-time updates, enhancing the overall user experience. StepSync's commitment to supporting a wide range of devices ensures users can seamlessly integrate their preferred wearables into their fitness routine, making it a versatile and user-centric choice.",
      },
      {
        heading: "Social Fitness Engagement:",
        image: require("@/assets/StepSync3.webp"),
        text: "StepSync goes beyond individual tracking by fostering a social fitness community. Users can compete with friends in step challenges, create or join teams, and celebrate achievements together. The app's engaging social features provide a motivating and enjoyable way to pursue fitness goals, making it an ideal choice for those who value a sense of community in their health journey.",
      },
    ],
  }),
};
</script>
